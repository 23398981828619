import { Box, Chip, Link, Tooltip } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { StripeIcon } from "../../../../lib/icons/StripeIcon";
import { chipStatusClass, chipStatusLabel, moneyFormatter } from '../../shared';
import { useStyles } from '../../styles';

const PaymentLineItem = ({ data, isFirstPaymentLineItem }) => {
  const classes = useStyles();

  return (
    <React.Fragment key={data.ID}>
      <TableRow key={data.ID}>
        <TableCell component="th" scope="row">
          {isFirstPaymentLineItem ? 'Buchung' : 'Umbuchung'}
        </TableCell>
        <TableCell>
          <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
            <span>
              {moment(data.Created).format('DD.MM.YYYY HH:MM')} Uhr
            </span>
            <Tooltip title="Zur Stripe-Rechnung">
              <Link
                href={`https://dashboard.stripe.com/invoices/${data.Payment.InvoiceID}`}
                sx={{display: 'flex'}}
                target={'new'}
              >
                <StripeIcon />
              </Link>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          {moneyFormatter(data.PriceCurrency).format(data.PriceAmount)}
        </TableCell>
        <TableCell>
          <Chip
            label={chipStatusLabel(data.Payment.InvoicePaid ? 'succeeded' : data.Payment.PaymentStatus)}
            className={chipStatusClass(data.Payment.InvoicePaid ? 'succeeded' : data.Payment.PaymentStatus, classes)}
            component={'span'}
            size="small"
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

PaymentLineItem.propTypes = {
  data: PropTypes.object
};

export default PaymentLineItem;

import { graphql } from '../../gql';

export const loginMutation = graphql(`
  mutation Login($username: String!, $password: String!, $grant_type: String!, $client_id: String!) {
    login(username: $username, password: $password, grant_type: $grant_type, client_id: $client_id) {
      ID
      token
      refresh_token
      expires_in
    }
  }
`)

export const refreshTokenMutation = graphql(`
  mutation RefreshToken($refresh_token: String!, $grant_type: String!, $client_id: String!) {
    refreshToken(refresh_token: $refresh_token, grant_type: $grant_type, client_id: $client_id) {
      ID
      token
      refresh_token
      expires_in
    }
  }
`)

export const addOnAnswerFragment = graphql(`
  fragment addOnAnswers on AddOnComponentAnswer {
    ID
    AddOnID
    BookingCollectionID
    OptionID
    OptionTitle
    ComponentID
    AddOnComponent {
      ID
      Title
      Options {
        ID
        Title
      }
    }
  }
`)

export const entityGroupsFragment = graphql(`
  fragment entityGroupsFragment on EntityGroup {
    TicketNumber
    IsUnplaced
    AdultsAmount
    ChildrenAmount
    BookingCollectionID
    RequestNumber
    IsCabinBooking
    CabinID
    CabinNumber
    PriceCategory
    PriceCategoryID
    Entities
    EntityIDs
    EntityType
    EntityTypeID
    WagonID
    WagonNumber
    BreakfastDone
    WakeUpTime
    WakeUpDone
    BlockedEntities {
      ArrivalStationPosition
      DepartureStationPosition
      EntityID
      ID
      WagonID
    }
  }
`)

export const overviewTicketDetails = graphql(`
  fragment overviewTicketDetails on Ticket {
    TicketNumber
    CanView
    CanEdit
    Created
    EmailPending
    BlockedMicroactions
    Status
    IsReturn
    IsCancelled
    MarkAsReleased
    TicketChipStatus
    OppositeDirectionTicket {
      TicketNumber
      IsCancelled
      StartDate
      Created
      IsReturn
      Status
      TicketChipStatus
      MarkAsReleased
    }
    DepartureStation
    ArrivalStation
    StartDate
    EntitySummary
    ConfirmationEmailType
    WagonSummary
    EntityGroups {
      ...entityGroupsFragment
    }
    AdultsAmount
    ChildrenAmount
    Customer {
      ID
      Firstname
      Lastname
      Phone
      Street
      Zip
      City
      Country
      Email
      EmailToken
      CustomerStatus
      CustomerLocale
      TcsMemberNumber
    }
    AddOnAnswers {
      ...addOnAnswers
    }
    AddOns {
      ID
      AddOnTypeID
      BookingCollectionID
      RequestNumber
      Amount
      Title
    }
    VehicleSlots {
      ID
      ReturnID
      Type
      Length
      Checked
      RoofWidth
      Height
      EmptyWeight
      LicensePlate
      VehicleGroupID
      Manufacturer
      VehicleModel
      RoofLuggage
      HasBikeRack
    }
    WakeUpTime
    WakeUpDone
    BreakfastDone
    Note
    ServiceCenterNote
    Checked
    TicketVersion
    Comment
  }
`);

export const allTicketDetails = graphql(`
  fragment allTicketDetails on Ticket {
    TicketNumber
    ...overviewTicketDetails
    DepartureStationID
    ArrivalStationID
    DepartureStationPosition
    ArrivalStationPosition
    Memberships {
      ID
      Title
      Payload
    }
    PaybackValues {
      ID
      Created
      Amount
      RemoteAmount
      TCSCashback
      Definite
      Type
      MembershipNumber
      ErrorMessage
    }
    CurrentReservationPrice {
      Amount
    }
  }
`);

export const readTicketVehicles = graphql(`
  query ReadOneTicketVehicles($Ticketnumber: String!){
    readOneTicket(Ticketnumber: $Ticketnumber) {
      TicketNumber
      IsReturn
      IsCancelled
      OppositeDirectionTicket {
        TicketNumber
        Created
        StartDate
        IsReturn
        IsCancelled
        VehicleSlots {
          ID
          ReturnID
        }
      }
      VehicleSlots {
        ID
        ReturnID
        Checked
        Type
        Length
        RoofWidth
        Height
        EmptyWeight
        LicensePlate
        VehicleGroupID
        Manufacturer
        VehicleModel
        RoofLuggage
        HasBikeRack
      }
    }
  }
`)

export const readMyselfQuery = graphql(`
  query ReadMyself {
    readMyself {
      ID
      FirstName
      Surname
      Email
      Permissions
      IsDebugMember
    }
  }
`);

export const readOneTicketQuery = graphql(`
  query ReadOneTicket($Ticketnumber: String!) {
    readOneTicket(Ticketnumber: $Ticketnumber) {
      ...allTicketDetails
      PendingInvoiceItems {
        ID
        Title
        Description
        Price {
          Amount
        }
      }
    }
  }
`);

export const readOneTicketQrCodeQuery = graphql(`
  query ReadOneTicketQrCode($Ticketnumber: String!) {
    readOneTicket(Ticketnumber: $Ticketnumber) {
      TicketNumber
      QrCode
    }
  }
`);

export const readLastTicketsQuery = graphql(`
  query ReadLastTickets($ts: String, $BookingBrand: String!, $limit: Int!, $skip: Int!) {
    readLastTickets(ts: $ts, BookingBrand: $BookingBrand, limit: $limit, skip: $skip) {
      OppositeDirectionTicket {
        TicketNumber
        Created
        StartDate
        IsReturn
      }
      IsReturn
      ...allTicketDetails
    }
  }
`);

export const ticketServices = graphql(`
  fragment ticketServices on Ticket {
    Payments {
      ID
      TotalFeePrice
      TotalRefundAmount
      PaymentStatus
    }
    Services {
      ID
      ServiceType
      Created
      Title
      PaymentStatus
      TotalPrice
      RefundPrice
      PaymentStatus
      IsCabinBooking
      IsMainForCabin
      PaymentLineItems {
        ID
        Created
        PriceCurrency
        PriceAmount
        Payment {
          ID
          PaymentStatus
          InvoicePaid
          InvoiceID
          ExtraCharges {
            ID
            FeeCurrency
            FeeAmount
          }
        }
        CreditNoteLineItems {
          ID
          PriceCurrency
          PriceAmount
          FeeCurrency
          FeeAmount
        }
      }
      PriceCategory {
        Title
      }
      Payments {
        ID
        Created
        CheckoutStatus
        PaymentStatus
        PaymentLineItems {
          ID
          PriceAmount
          Booking {
            ID
            Type
            Entity {
              ID
              Cabin {
                ID
                EntityType
              }
            }
          }
          CreditNoteLineItems {
            ID
            PriceAmount
            PriceCurrency
            FeeAmount
            FeeCurrency
          }
        }
        ExtraCharges {
          ID
          FeeCurrency
          FeeAmount
        }
      }
      PriceCategory {
        ID
        Title
      }
    }
  }
`);

export const ticketAddons = graphql(`
  fragment ticketAddons on Ticket {
    AddOns {
      ID
      AddOnTypeID
      BookingCollectionID
      RequestNumber
      Title
      Amount
      Status
      TotalPriceInclFees
      TotalPrice
      TotalFees
      RefundPrice
      PaymentStatus
      AddOnType {
        ID
        GroupJourneys
      }
      Payments {
        ID
        Created
        CheckoutStatus
        PaymentStatus
        TotalPriceAmount
        TotalRefundAmount
        TotalFeePrice
        PaymentLineItems {
          ID
          PriceCurrency
          PriceAmount
          Booking {
            ID
            Type
            PriceCategory {
              Title
            }
            Entity {
              Cabin {
                EntityType
              }
            }
          }
          CreditNoteLineItems {
            ID
            PriceCurrency
            PriceAmount
            FeeCurrency
            FeeAmount
          }
        }
        ExtraCharges {
          ID
          FeeCurrency
          FeeAmount
        }
      }
    }
  }
`);

export const ticketVehicles = graphql(`
  fragment ticketVehicles on Ticket {
    VehicleSlots {
      ID
      Type
      Length
      Checked
      RoofWidth
      Height
      EmptyWeight
      LicensePlate
      VehicleGroupID
      Manufacturer
      RoofLuggage
      HasBikeRack
      PaymentStatus
      TotalPriceInclFees
      TotalPrice
      TotalFees
      RefundPrice
      Payments {
        ID
        Created
        CheckoutStatus
        PaymentStatus
        TotalPriceAmount
        TotalRefundAmount
        TotalFeePrice
        PaymentLineItems {
          ID
          PriceCurrency
          PriceAmount
          Booking {
            ID
            Type
            PriceCategory {
              Title
            }
            Entity {
              Cabin {
                EntityType
              }
            }
          }
          CreditNoteLineItems {
            ID
            PriceCurrency
            PriceAmount
            FeeCurrency
            FeeAmount
          }
        }
        ExtraCharges {
          ID
          FeeCurrency
          FeeAmount
        }
      }
    }
  }
`);

export const ticketPayments = graphql(`
  fragment ticketPayments on Ticket {
    Payments {
      ID
      Created
      CheckoutStatus
      PaymentStatus
      TotalPriceAmount
      TotalRefundAmount
      TotalFeePrice
      PaymentLineItems {
        ID
        PriceCurrency
        PriceAmount
        Booking {
          ID
          Type
          PriceCategory {
            Title
          }
          Entity {
            Cabin {
              EntityType
            }
          }
        }
        CreditNoteLineItems {
          ID
          PriceCurrency
          PriceAmount
          FeeCurrency
          FeeAmount
        }
      }
      ExtraCharges {
        ID
        FeeCurrency
        FeeAmount
      }
    }
  }
`);

export const readOneticketPaymentsQuery = graphql(`
  query ReadOneTicketForPayment($Ticketnumber: String!, $IncludeInactiveEntities: Boolean) {
    readOneTicket(Ticketnumber: $Ticketnumber, IncludeInactiveEntities: $IncludeInactiveEntities) {
      TicketNumber
      Status
      IsCancelled
      AdultsAmount
      ChildrenAmount
      ReservationStripeCustomerID
      IsReturn
      DaysUntilDeparture
      ...ticketServices
      OppositeDirectionTicket {
        TicketNumber
        Status
        IsCancelled
        AdultsAmount
        ChildrenAmount
        ReservationStripeCustomerID
        IsReturn
        DaysUntilDeparture
        ...ticketServices
      }
    }
  }
`);

export const readTicketHistoryQuery = graphql(`
  query ReadTicketHistory($Ticketnumber: String!) {
    readOneTicket(Ticketnumber: $Ticketnumber) {
      TicketNumber
      IsCancelled
      History {
        ID
        Timestamp
        Message
      }
    }
  }
`);

export const searchTicketSummariesQuery = graphql(`
  query SearchTicketSummaries($Query: String) {
    readTicketSummaries(Query: $Query) {
      CanView
      BookingDate
      TicketNumber
      StartDate
      DepartureStation
      ArrivalStation
      BookingCollections
      Vehicles
      AddOns
      Name
      Email
      Phone
      Address
      Note
      Comment
      ServiceCenterNote
      TicketChipStatus
    }
  }
`)

export const searchTicketsQuery = graphql(`
  query SearchTickets($Query: String, $Ticketnumber: String, $Name: String) {
    readTickets(Query: $Query, Ticketnumber: $Ticketnumber, Name: $Name) {
      TicketNumber
      IsCancelled
      MarkAsReleased
      ConfirmationEmailType
      CanView
      CanEdit
      ArrivalStation
      DepartureStation
      StartDate
      EntitySummary
      AdultsAmount
      ChildrenAmount
      WagonSummary
      EntityGroups {
        ...entityGroupsFragment
      }
      Checked
      Customer {
        Firstname
        Lastname
        Email
      }
      VehicleSlots {
        ID
        Checked
      }
    }
  }
`);

export const searchTrainQuery = graphql(`
  query SearchTrain($SearchQuery: String) {
    readSearchTrainConnections(SearchQuery: $SearchQuery) {
      ID
      ArrivalStation
      DepartureStation
      StartDate
      TrainTitle
    }
  }
`);

export const readAvailableEntities = graphql(`
  query ReadAvailablilities($Ticketnumber: String!, $CollectionID: String!) {
    readAvailableEntities(Ticketnumber: $Ticketnumber, CollectionID: $CollectionID) {
      ID
      WagonNumber
      Cabins {
        ID
        CabinNumber
        EntityType
        Entities {
          ID
          Title
          BarrierFree
        }
      }
    }
  }
`);

export const readAllAvailableEntities = graphql(`
  query ReadAllAvailablilities($Ticketnumber: String!, $CollectionID: String!) {
    readAllAvailableEntities(Ticketnumber: $Ticketnumber, CollectionID: $CollectionID) {
      ID
      WagonNumber
      Cabins {
        ID
        CabinNumber
        EntityType
        Entities {
          ID
          Title
          BarrierFree
        }
      }
    }
  }
`);

export const readAddOnTypeComponents = graphql(`
  query ReadAddOnTypeComponents {
    readAddOnTypeComponents {
      ID
      Title
      AddOnTypeID
      Options {
        ID
        Title
      }
    }
  }
`);

export const readTrainConnectionsList = graphql(`
  query ReadTrainConnectionsList($Date: String, $Limit: Boolean) {
    readTrainConnections(Date: $Date, Limit: $Limit) {
      ID
      TrainTitle
      DepartureStation
      ArrivalStation
      StartDate
      DisableConnection
    }
  }
`);

export const readTrainConnections = graphql(`
  query ReadTrainConnections($Date: String) {
    readTrainConnections(Date: $Date) {
      ID
      TrainTitle
      DepartureStation
      ArrivalStation
      StartDate
      Member {
        ID
        FirstName
        Surname
      }
    }
  }
`);

export const readOneTrainConnectionConfig = graphql(`
  query ReadOneTrainConnectionConfig($TrainConnectionID: String!) {
    readOneTrainConnection(TrainConnectionID: $TrainConnectionID) {
      ID
      TrainTitle
      DepartureStation
      ArrivalStation
      StartDate
      DisableConnection
      Wagons {
        ID
        WagonNumber
        Code
        Cabins {
          ID
          CabinNumber
          EntityType
          Entities {
            ID
            Title
          }
        }
      }
      BlockedCabins {
        ID
        WagonID
        CabinID
        WagonNumber
        CabinNumber
        EntityType
        BlockingReason
      }
      RemovedWagonIDs
      RemovedCabinIDs
      AddedWagonIDs
      Route {
        ID
        Code
        Stations {
          ID
          Title
        }
      }
    }
  }
`);

export const readOneTrainConnectionsTickets = graphql(`
  query ReadOneTrainConnectionTickets($TrainConnectionID: String!) {
    readOneTrainConnection(TrainConnectionID: $TrainConnectionID) {
      ID
      TrainTitle
      DepartureStation
      ArrivalStation
      StartDate
      DisableConnection
      Tickets {
        ...overviewTicketDetails
      }
    }
  }
`);

export const readOneTrainConnection = graphql(`
  query ReadOneTrainConnection($TrainConnectionID: String!) {
    readOneTrainConnection(TrainConnectionID: $TrainConnectionID) {
      ID
      TrainTitle
      DepartureStation
      ArrivalStation
      StartDate
      DisableConnection
      Member {
        ID
        FirstName
        Surname
      }
      Tickets {
        ...allTicketDetails
      }
      RemovedWagonIDs
    }
  }
`);

export const trainConnectionOccupancyFragment = graphql(`
  fragment trainConnectionOccupancyFragment on TrainConnection {
    ID
    StartDate
    TrainTitle
    DepartureStation
    ArrivalStation
    DisableConnection
    StatisticSales
    StatisticVehicleSlotSales
    StatisticTicketAmount
    StatisticPersons
    StatisticVehicleSlotsAmount
    MaxTopWeight
    MaxTotalWeight
    VehicleOccupancy {
      TrainConnectionID
      CategoryKey
      Weight
      Percentage
      PercentageBlocked
      HasOverruleSlots
      BlockedAmount
    }
    TrainOccupancy {
      TrainConnectionID
      Code
      Title
      TotalEntities
      BookedEntities
      BlockedEntities
      OccupancyPercentage
      YieldStep
    }
  }
`)

export const readAllTrainConnectionOccupancyQuery = graphql(`
  query ReadAllTrainOccupancy($Date: String) {
    readTrainConnections(Date: $Date, Limit: true, SortDirection: "ASC") {
      ...trainConnectionOccupancyFragment
    }
  }
`)

export const readOneTrainConnectionOccupancyQuery = graphql(`
  query ReadOneTrainOccupancy($TrainConnectionID: String!) {
    readOneTrainConnection(TrainConnectionID: $TrainConnectionID) {
      ...trainConnectionOccupancyFragment
    }
  }
`)

export const readStatistic = graphql(`
  query ReadOneTrainConnectionStatistic($TrainConnectionID: String!) {
    readOneTrainConnection(TrainConnectionID: $TrainConnectionID) {
      ID
      StatisticTicketAmount
      StatisticVehicleSlotsAmount
      StatisticCheckedIn
      StatisticTicketAmount
      StatisticPersons
      StatisticBreakfast
      StatisticSales
      StatisticVehicleSlotSales
      StatisticEntitySales {
        ID
        Title
        Amount
      }
    }
  }
`);

export const readTrainConnectionMessagesQuery = graphql(`
  query ReadTrainConnectionMessages($TrainConnectionID: String!) {
    readOneTrainConnection(TrainConnectionID: $TrainConnectionID) {
      ID
      EmailMessages {
        ID
        Created
        Type
        EmailAmount
        EmailSentAmount
        Summary
        ContentDE
        ContentEN
      }
    }
  }
`);

export const readTrainConnectionTicketsQuery = graphql(`
  query ReadTrainConnectionTickets($TrainConnectionID: String!) {
    readOneTrainConnection(TrainConnectionID: $TrainConnectionID) {
      ID
      Tickets {
        ...allTicketDetails
      }
      PreviewLinkTicketEmail
      PreviewLinkFeedbackEmail
      PreviewLinkEmailMessage
    }
  }
`);

export const readMember = graphql(`
  query ReadMember {
    readMember {
      ID
      FirstName
      Surname
      Email
    }
  }
`);

export const readOccupancyData = graphql(`
  query ReadOccupancyData($TrainConnectionID: String!) {
    readOneTrainConnection(TrainConnectionID: $TrainConnectionID) {
      ID
      TrainTitle
      StartDate
      DepartureStation
      ArrivalStation
      DisableConnection
      Tickets {
        TicketNumber
        CanEdit
        Checked
        IsCancelled
        MarkAsReleased
        ConfirmationEmailType
        TicketChipStatus
        AddOns {
          ID
          BookingCollectionID
          AddOnTypeID
        }
        IsCancelled
        DepartureStationPosition
        ArrivalStationPosition
        BlockedEntities {
          ID
          WagonID
          EntityID
          DepartureStationPosition
          ArrivalStationPosition
        }
        EntityGroups {
          ...entityGroupsFragment
        }
        VehicleSlots {
          ID
          Type
          Length
          Checked
          RoofWidth
          Height
          EmptyWeight
          LicensePlate
          VehicleGroupID
          Manufacturer
          VehicleModel
          RoofLuggage
          HasBikeRack
        }
      }
      Wagons {
        ID
        WagonNumber
        Code
        Cabins {
          ID
          CabinNumber
          EntityType
          Entities {
            ID
            Title
          }
        }
      }
      BlockedCabins {
        ID
        WagonID
        CabinID
        DepartureStationPosition
        ArrivalStationPosition
      }
      RemovedWagonIDs
      RemovedCabinIDs
      AddedWagonIDs
      Route {
        ID
        Code
        Stations {
          ID
          Title
        }
      }
    }
  }
`);

export const validateTicketMutation = graphql(`
  mutation ValidateTicket($Ticketnumber: String!, $Checked: Boolean!) {
    validateTicket(Ticketnumber: $Ticketnumber, Checked: $Checked) {
      TicketNumber
      Checked
      MarkAsReleased
      Status
      TicketChipStatus
    }
  }
`);

export const validateVehicleSlotMutation = graphql(`
  mutation ValidateVehicleSlot($ID: String!, $Checked: Boolean!) {
    validateVehicleSlot(ID: $ID, Checked: $Checked) {
      TicketNumber
      MarkAsReleased
      Status
      TicketChipStatus
      VehicleSlots {
        ID
        Checked
      }
    }
  }
`);

export const doWakeUpMutation = graphql(`
  mutation DoWakeUp($Ticketnumber: String!, $BookingCollectionID: String!) {
    doWakeUp(Ticketnumber: $Ticketnumber, BookingCollectionID: $BookingCollectionID) {
      TicketNumber
      EntityGroups {
        TicketNumber
        BookingCollectionID
        WakeUpDone
      }
    }
  }
`);

export const breakfastDoneMutation = graphql(`
  mutation DoBreakfast($Ticketnumber: String!, $BookingCollectionID: String!) {
    doBreakfast(Ticketnumber: $Ticketnumber, BookingCollectionID: $BookingCollectionID) {
      TicketNumber
      EntityGroups {
        BookingCollectionID
        TicketNumber
        BreakfastDone
      }
    }
  }
`);

export const changeAddOnAnswerMutation = graphql(`
  mutation ChangeAddOnAnswer(
    $AddOnAnswerID: ID
    $AddOnID: ID
    $OptionID: ID!
    $OptionTitle: String!
    $ComponentID: ID
    $Timestamp: String!
  ) {
    changeAddOnAnswer(
      AddOnAnswerID: $AddOnAnswerID
      AddOnID: $AddOnID
      OptionID: $OptionID
      OptionTitle: $OptionTitle
      ComponentID: $ComponentID
      Timestamp: $Timestamp
    ) {
      TicketNumber
      AddOnAnswers {
        ...addOnAnswers
      }
    }
  }
`);

export const setWakeUpTimeMutation = graphql(`
  mutation SetWakeUpTime($Ticketnumber: String!, $BookingCollectionID: String!, $Time: String!) {
    setWakeUpTime(Ticketnumber: $Ticketnumber, BookingCollectionID: $BookingCollectionID, Time: $Time) {
      TicketNumber
      EntityGroups {
        TicketNumber
        BookingCollectionID
        WakeUpTime
      }
    }
  }
`);

export const setNoteMutation = graphql(`
  mutation SetNote($Ticketnumber: String!, $Text: String!) {
    setNote(Ticketnumber: $Ticketnumber, Text: $Text) {
      TicketNumber
      EmailPending
      BlockedMicroactions
      Note
    }
  }
`);

export const setServiceCenterNoteMutation = graphql(`
  mutation SetServiceCenterNote($Ticketnumber: String!, $Text: String!) {
    setServiceCenterNote(Ticketnumber: $Ticketnumber, Text: $Text) {
      TicketNumber
      EmailPending
      ServiceCenterNote
    }
  }
`);

export const setCommentMutation = graphql(`
  mutation SetComment($Ticketnumber: String!, $Text: String!) {
    setComment(Ticketnumber: $Ticketnumber, Text: $Text) {
      TicketNumber
      EmailPending
      BlockedMicroactions
      Comment
    }
  }
`);

export const moveBookingCollectionMutation = graphql(`
  mutation MoveBookingCollection(
    $TicketNumber: String!
    $CollectionID: String!
    $WagonID: String!
    $CabinID: String!
    $BlockCabin: Boolean
    $BlockCabinText: String
    $SendTicketEmail: Boolean
    $NewEntityType: Boolean
  ) {
    moveBookingCollection(
      TicketNumber: $TicketNumber
      CollectionID: $CollectionID
      WagonID: $WagonID
      CabinID: $CabinID
      BlockCabin: $BlockCabin
      BlockCabinText: $BlockCabinText
      SendTicketEmail: $SendTicketEmail
      NewEntityType: $NewEntityType
    ) {
      TicketNumber
      EmailPending
      BlockedMicroactions
      DepartureStationPosition
      ArrivalStationPosition
      EntitySummary
      WagonSummary
      PendingInvoiceItems {
        ID
        Title
        Description
        Price {
          Amount
        }
      }
      EntityGroups {
        ...entityGroupsFragment
      }
      BlockedEntities {
        ID
        WagonID
        EntityID
        DepartureStationPosition
        ArrivalStationPosition
      }
    }
  }
`);

export const unplaceBookingCollectionMutation = graphql(`
  mutation UnplaceBookingCollection($TicketNumber: String!, $CollectionID: String!) {
    unplaceBookingCollection(TicketNumber: $TicketNumber, CollectionID: $CollectionID) {
      TicketNumber
      EmailPending
      BlockedMicroactions
      DepartureStationPosition
      ArrivalStationPosition
      EntitySummary
      WagonSummary
      EntityGroups {
        ...entityGroupsFragment
      }
      BlockedEntities {
        ID
        WagonID
        EntityID
        DepartureStationPosition
        ArrivalStationPosition
      }
    }
  }
`)

export const switchBookingCollectionMutation = graphql(`
  mutation SwitchBookingCollection($TicketNumber: String!, $CollectionID: String!, $TargetTicketNumber: String!, $TargetCollectionID: String!) {
    switchBookingCollection(TicketNumber: $TicketNumber, CollectionID: $CollectionID, TargetTicketNumber: $TargetTicketNumber, TargetCollectionID: $TargetCollectionID) {
      ID
      TicketNumber
      BlockedEntities {
        ID
        WagonID
        EntityID
        DepartureStationPosition
        ArrivalStationPosition
      }
      EntityGroups {
        ...entityGroupsFragment
      }
    }
  }
`);

export const releaseTicketMutation = graphql(`
  mutation ReleaseTicket($Ticketnumber: String!, $MarkAsReleased: Boolean) {
    releaseTicket(Ticketnumber: $Ticketnumber, MarkAsReleased: $MarkAsReleased) {
      TicketNumber
      EmailPending
      BlockedMicroactions
      EntitySummary
      WagonSummary
      MarkAsReleased
      Status
      TicketChipStatus
      EntityGroups {
        ...entityGroupsFragment
      }
    }
  }
`);
export const updateStaffMutation = graphql(`
  mutation UpdateStaff($TrainConnectionID: String!, $Member: [String!]!) {
    updateStaff(TrainConnectionID: $TrainConnectionID, Member: $Member) {
      ID
      Member {
        ID
        FirstName
        Surname
      }
    }
  }
`);

// export const loginMutation = graphql(`
//   mutation Login($Email: String!, $Password: String!) {
//     login(Email: $Email, Password: $Password) {
//       ID
//       FirstName
//       Surname
//       Email
//       Permissions
//     }
//   }
// `);

export const logoutMutation = graphql(`
  mutation Logout {
    logout
  }
`);

export const feedbackQuery = graphql(`
  query ReadFeedback($brandID: String!, $start: String, $end: String) {
    readBrandConfigs {
      ID
      Title
      Identifier
    }
    readBrandConfig(brandID: $brandID) {
      ID
      Identifier
      FeedbackQuestions {
        ID
        Title
        ShortTitle
        Type
      }
    }
    readFeedback(brandID: $brandID, start: $start, end: $end) {
      ID
      Created
      IsExternal
      FeedbackAnswers {
        ID
        FeedbackQuestionID
        Value
        Comment
      }
      Ticket {
        TicketNumber
        StartDate
        Customer {
          Firstname
          Lastname
        }
        TrainConnection {
          ID
          DepartureStation
          ArrivalStation
        }
      }
      External {
        ID
        Date
        TicketNumber
        TrainConnectionID
        Firstname
        Lastname
        Departure
        Arrival
        CustomerLocale
        Adults
        Children
      }
    }
  }
`);

export const readTicketAddOns = graphql(`
  query ReadTicketAddOns($Ticketnumber: String!) {
    readOneTicket(Ticketnumber: $Ticketnumber) {
      TicketNumber
      IsReturn
      IsCancelled
      AddOns {
        ID
        BookingCollectionID
        RequestNumber
        AddOnTypeID
        AddOnType {
          ID
          Title
          GroupJourneys
        }
        ItemValue
        Amount
        Title
      }
      OppositeDirectionTicket {
        TicketNumber
        Created
        StartDate
        IsReturn
        IsCancelled
        AddOns {
          ID
          AddOnTypeID
          BookingCollectionID
          RequestNumber
          AddOnType {
            ID
            Title
            GroupJourneys
          }
          ItemValue
          Amount
          Title
        }
      }
    }
  }
`);

export const readMicroactionAddOnsByTicket = graphql(`
  query ReadMicroactionAddOnsByTicket($TicketNumber: ID!) {
    readMicroactionAddOnsByTicket(TicketNumber: $TicketNumber) {
      ID
      Title
      Limit
      ReturnLimit
      HideAway
      HideReturn
      GroupJourneys
      Binding
      Price {
        Amount
      }
      PriceReturn {
        Amount
      }
    }
  }
`);
export const readMicroactionAddOnPriceQuery = graphql(`
  query ReadMicroactionAddOnPrice(
    $Tickets: [MicroactionAddOnInput],
    $Fee: Int,
    $Coupons: [MicroactionCouponInput],
    $ChangePrice: Boolean
  ) {
    readMicroactionAddOnPrice(
      Tickets: $Tickets,
      Fee: $Fee,
      Coupons: $Coupons,
      ChangePrice: $ChangePrice
    ) {
      Taxes {
        Rate
        Price {
          Amount
          Currency
        }
      }
      Price {
        Amount
        Currency
      }
    }
  }
`);
export const addAddOnMicroactionMutation = graphql(`
  mutation AddAddOnMicroaction(
    $Tickets: [MicroactionAddOnInput],
    $Fee: Int,
    $Coupons: [MicroactionCouponInput],
    $ChangePrice: Boolean
  ) {
    addAddOnMicroaction(
      Tickets: $Tickets,
      Fee: $Fee,
      Coupons: $Coupons,
      ChangePrice: $ChangePrice
    ) {
      TicketNumber
      EmailPending
      BlockedMicroactions
      PendingInvoiceItems {
        ID
        Title
        Description
        Price {
          Amount
        }
      }
      AddOns {
        ID
        Title
        Amount
        BookingCollectionID
        RequestNumber
      }
    }
  }
`);

export const resendTicketEmailMutation = graphql(`
  mutation ResendTicketEmail($TicketNumber: String!, $SendAwayConfirmation: Boolean, $SendReturnConfirmation: Boolean) {
    resendTicketEmail(TicketNumber: $TicketNumber, SendAwayConfirmation: $SendAwayConfirmation, SendReturnConfirmation: $SendReturnConfirmation) {
      TicketNumber
      EmailPending
      BlockedMicroactions
      PendingInvoiceItems {
        ID
        Title
        Description
        Price {
          Amount
        }
      }
      OppositeDirectionTicket {
        TicketNumber
        EmailPending
        BlockedMicroactions
        PendingInvoiceItems {
          ID
          Title
          Description
          Price {
            Amount
          }
        }
      }
    }
  }
`);

export const profileMutation = graphql(`
  mutation ChangePassword(
    $Email: String!
    $OldPassword: String!
    $Password: String!
  ) {
    changePassword(
      Email: $Email
      OldPassword: $OldPassword
      Password: $Password
    ) {
      ID
      Email
      FirstName
      Surname
    }
  }
`);

export const cancelServiceMutation = graphql(`
  mutation CancelService($CancellationFeePercentage: Int!, $Tickets: [MicroactionServiceCancellationListInput], $IncludeInactiveEntities: Boolean) {
    cancelServiceMicroaction(CancellationFeePercentage: $CancellationFeePercentage, Tickets: $Tickets, IncludeInactiveEntities: $IncludeInactiveEntities) {
      Refund {
        Amount
        Currency
      }
      Ticket {
        TicketNumber
        EmailPending
        BlockedMicroactions
        ...ticketServices
        WagonSummary
        EntityGroups {
          ...entityGroupsFragment
        }
        TicketChipStatus
        OppositeDirectionTicket {
          TicketNumber
          EmailPending
          BlockedMicroactions
          ...ticketServices
          StartDate
          WagonSummary
          EntityGroups {
            ...entityGroupsFragment
          }
          TicketChipStatus
        }
      }
    }
  }
`);

export const syncCancelStateTicketMutation = graphql(`
  mutation SyncCancelStateTicket($TicketNumber: String!) {
    syncCancelStateTicket(TicketNumber: $TicketNumber) {
      Currency
      Amount
    }
  }
`);

export const readMicroactionVehiclePrice = graphql(`
  query ReadMicroactionVehiclePrice($input: MicroactionVehicleMicroactionInput!) {
    readMicroactionVehiclePrice(input: $input) {
       Vehicles {
         ID
         Price {
           Amount
           Currency
        }
      }
      TotalPrice {
        Amount
        Currency
      }
    }
  }
`)

export const vehicleMicroactionMutation = graphql(`
  mutation VehicleMicroaction($input: MicroactionVehicleMicroactionInput!) {
    vehicleMicroaction(input: $input) {
      TicketNumber
      EmailPending
      BlockedMicroactions
      PendingInvoiceItems {
        ID
        Title
        Description
        Price {
          Amount
        }
      }
      VehicleSlots {
        ID
        Type
        Checked
        Length
        RoofWidth
        Height
        EmptyWeight
        LicensePlate
        VehicleGroupID
        Manufacturer
        VehicleModel
        RoofLuggage
        HasBikeRack
      }
    }
  }
`)


export const readCancelPriceQuery = graphql(`
  query ReadCancelPrice(
    $ServiceItems: [ServiceItemInput]!
    $RefundPercentage: Int!
  ) {
    readCancelPrice(
      ServiceItems: $ServiceItems
      RefundPercentage: $RefundPercentage
    ) {
      Items {
        ID
        Type
        Refund {
          Amount
          Currency
        }
        DiscountAdjustment {
          Amount
          Currency
        }
      }
      TotalRefund {
        Amount
        Currency
      }
      TotalDiscountAdjustment {
        Amount
        Currency
      }
    }
  }
`);

export const readMicroActionEntityTypesQuery = graphql(`
  query ReadMicroactionEntityTypes($TicketNumber: String!) {
    readMicroactionEntityTypes (TicketNumber: $TicketNumber) {
      ID
      Title
      BookingOptions {
        Title
        Code
      }
    }
  }
`)

export const readMicroactionPriceCategoriesQuery = graphql(`
  query ReadMicroactionPriceCategories($TicketNumber: String!, $RequestID: ID!, $AdultsAmount: Int!, $ChildrenAmount: Int!, $EntityTypeHashID: String!, $IsCabinBooking: Boolean!) {
    readMicroactionPriceCategories(TicketNumber: $TicketNumber, RequestID: $RequestID, AdultsAmount: $AdultsAmount, ChildrenAmount: $ChildrenAmount, EntityTypeHashID: $EntityTypeHashID, IsCabinBooking: $IsCabinBooking) {
      ID
      Title
      IsInternal
      SinglePrice {
        Amount
      }
      Price {
        Amount
      }
      ReturnSinglePrice {
        Amount
      }
      ReturnPrice {
        Amount
      }
    }
  }
`)

export const readMicroactionCabinPriceQuery = graphql(`
  query ReadMicroactionCabinPrice($input: MicroactionCabinInput!) {
    readMicroactionCabinPrice(input: $input) {
      Price {
        Amount
      }
      CouponValue {
        Title
        Price {
          Amount
          Currency
        }
      }
      Refund {
        Amount
      }
    }
  }
`)

export const cabinMicroactionMutation = graphql(`
  mutation CabinMicroaction($input: MicroactionCabinInput!) {
    cabinMicroaction(input: $input) {
      TicketNumber
      EmailPending
      BlockedMicroactions
      AdultsAmount
      ChildrenAmount
      EntitySummary
      WagonSummary
      PendingInvoiceItems {
        ID
        Title
        Description
        Price {
          Amount
        }
      }
      EntityGroups {
        ...entityGroupsFragment
      }
    }
  }
`)

export const readMicroactionMaximumPersons = graphql(`
  query ReadMicroactionMaximumPersons($TicketNumber: String!, $EntityType: String) {
    readMicroactionMaximumPersons(TicketNumber: $TicketNumber, EntityType: $EntityType)
  }
`)

export const personalDataMicroactionMutation = graphql(`
  mutation PersonalDataMicroaction(
    $ID: ID,
    $Firstname: String,
    $Lastname: String,
    $Phone: String,
    $Street: String,
    $Zip: String,
    $City: String,
    $Country: String,
    $Email: String,
    $CustomerStatus: String,
    $CustomerLocale: String
) {
    personalDataMicroaction(
      ID: $ID,
      Firstname: $Firstname,
      Lastname: $Lastname,
      Phone: $Phone,
      Street: $Street,
      Zip: $Zip,
      City: $City,
      Country: $Country,
      Email: $Email,
      CustomerStatus: $CustomerStatus,
      CustomerLocale: $CustomerLocale
    ) {
      TicketNumber
      EmailPending
      BlockedMicroactions
      OppositeDirectionTicket {
        TicketNumber
        EmailPending
        BlockedMicroactions
        Created
        StartDate
        Customer {
          ID
        }
      }
      Customer {
        ID
        Firstname
        Lastname
        Phone
        Street
        Zip
        City
        Country
        Email
        EmailToken
        CustomerStatus
        CustomerLocale
        TcsMemberNumber
      }
    }
  }
`)

export const readTrainOccupancyCSV = graphql(`
  query ReadTrainOccupancyCSV($input: ReadTrainOccupancyCSVInput!) {
    readTrainOccupancyCSV(input: $input)
  }
`)

export const readBlockedCabinsQuery = graphql(`
  query ReadBlockedCabins($TrainConnectionID: ID!) {
    readBlockedCabins(TrainConnectionID: $TrainConnectionID) {
      ID
      WagonID
      CabinID
      WagonNumber
      CabinNumber
      EntityType
      BlockingReason
    }
  }
`)


export const readBlockableCabinsForTrainConnectionQuery = graphql(`
  query ReadBlockableCabinsForTrainConnection($TrainConnectionID: ID!) {
    readBlockableCabinsForTrainConnection(TrainConnectionID: $TrainConnectionID) {
      ID
      WagonNumber
      Cabins {
        ID
        CabinNumber
        EntityType
      }
    }
  }
`);

export const addOneWagonMutation = graphql(`
  mutation AddOneWagon($input: AddOneWagonInput!) {
    addOneWagon(input: $input) {
      ID
      Wagons {
        ID
        WagonNumber
        Code
        Cabins {
          ID
          CabinNumber
          EntityType
          Entities {
            ID
            Title
          }
        }
      }
      AddedWagonIDs
    }
  }
`)

export const renameTrainAdjustmentWagonMutation = graphql(`
  mutation RenameTrainAdjustmentWagon($input: RenameTrainAdjustmentWagonInput!) {
    renameTrainAdjustmentWagon(input: $input) {
      ID
      Wagons {
        ID
        WagonNumber
      }
    }
  }
`)

export const blockOneWagonMutation = graphql(`
  mutation BlockOneWagon($TrainConnectionID: ID, $WagonID: String) {
    blockOneWagon(
      TrainConnectionID: $TrainConnectionID
      WagonID: $WagonID
    ) {
      ID
      Wagons {
        ID
        WagonNumber
        Code
        Cabins {
          ID
          CabinNumber
          EntityType
          Entities {
            ID
            Title
          }
        }
      }
      AddedWagonIDs
      RemovedWagonIDs
    }
  }
`)

export const removeWagonBlockingMutation = graphql(`
  mutation RemoveWagonBlocking(
    $TrainConnectionID: ID!,
    $WagonID: ID!
  ) {
    removeWagonBlocking(
      TrainConnectionID: $TrainConnectionID,
      WagonID: $WagonID
    ) {
      ID
      RemovedWagonIDs
    }
  }
`)

export const blockOneCabinMutation = graphql(`
  mutation BlockOneCabin(
    $TrainConnectionID: ID
    $WagonID: String
    $CabinID: String
    $BlockCabinText: String
  ) {
    blockOneCabin(
      TrainConnectionID: $TrainConnectionID
      WagonID: $WagonID
      CabinID: $CabinID
      BlockCabinText: $BlockCabinText
    ) {
      ID
      BlockedCabins {
        ID
        WagonID
        CabinID
        WagonNumber
        CabinNumber
        EntityType
        BlockingReason
        DepartureStationPosition
        ArrivalStationPosition
      }
    }
  }
`)

export const removeCabinBlockingMutation = graphql(`
  mutation RemoveCabinBlocking(
    $TrainConnectionID: ID!,
    $WagonID: ID!,
    $CabinID: ID!
  ) {
    removeCabinBlocking(
      TrainConnectionID: $TrainConnectionID,
      WagonID: $WagonID,
      CabinID: $CabinID
    ) {
      ID
      BlockedCabins {
        ID
        WagonID
        CabinID
        WagonNumber
        CabinNumber
        EntityType
        BlockingReason
        DepartureStationPosition
        ArrivalStationPosition
      }
    }
  }
`)

export const readBlockedVehiclesQuery = graphql(`
  query ReadBlockedVehicles($TrainConnectionID: ID!) {
    readBlockedVehicles(TrainConnectionID: $TrainConnectionID) {
      ID
      Category
      BlockingReason
    }
  }
`)

export const readVehicleSlotAvailabilityQuery = graphql(`
  query ReadVehiclesSlotAvailability($TrainConnectionID: ID!) {
    readVehiclesSlotAvailability(TrainConnectionID: $TrainConnectionID) {
      ID
      CategoryKey
      Amount
    }
  }
`)

export const blockVehiclesMutation = graphql(`
  mutation BlockVehicles($TrainConnectionID: ID!, $Categories: [String]!, $BlockingReason: String) {
    blockVehicles(TrainConnectionID: $TrainConnectionID, Categories: $Categories, BlockingReason: $BlockingReason) {
      ID
      Category
    }
  }
`)

export const removeVehicleBlockingMutation = graphql(`
  mutation RemoveVehicleBlocking($TrainConnectionID: ID!, $BlockedVehicleID: ID!) {
    removeVehicleBlocking(TrainConnectionID: $TrainConnectionID, BlockedVehicleID: $BlockedVehicleID) {
      ID
      Category
    }
  }
`)

export const addOverruleVehicleMutation = graphql(`
  mutation AddOverruleVehicle($TrainConnectionID: ID!, $Categories: [String]!, $Reason: String) {
    addOverruleVehicle(TrainConnectionID: $TrainConnectionID, Categories: $Categories, Reason: $Reason) {
      ID
      Category
    }
  }
`)

export const removeOverruleVehicleMutation = graphql(`
  mutation RemoveOverruleVehicle($TrainConnectionID: ID!, $OverruleVehicleID: ID!) {
    removeOverruleVehicle(TrainConnectionID: $TrainConnectionID, OverruleVehicleID: $OverruleVehicleID) {
      ID
      Category
    }
  }
`)

export const readOverruleVehiclesQuery = graphql(`
  query ReadOverruleVehicles($TrainConnectionID: ID!) {
    readOverruleVehicles(TrainConnectionID: $TrainConnectionID) {
      ID
      Category
      Reason
    }
  }
`)

export const readMicroactionTrainConnectionAvailabilityQuery = graphql(`
  query ReadMicroactionTrainConnectionAvailability(
    $DepartureStationID: Int!,
    $ArrivalStationID: Int!,
    $VehiclesEnabled: Boolean!,
    $ChangeDirection: Boolean
  ) {
    readMicroactionTrainConnectionAvailability(
      DepartureStationID: $DepartureStationID,
      ArrivalStationID: $ArrivalStationID,
      VehiclesEnabled: $VehiclesEnabled,
      ChangeDirection: $ChangeDirection
    ) {
      HashID
      StartDate
    }
  }
`)

export const readMicroactionTrainConnectionPriceQuery = graphql(`
  query ReadMicroactionTrainConnectionPrice($input: MicroactionConnectionInput!) {
    readMicroactionTrainConnectionPrice(input: $input) {
      Price {
        Amount
      }
      Refund {
        Amount
      }
    }
  }
`)

export const trainConnectionMicroactionMutation = graphql(`
  mutation TrainConnectionMicroaction($input: MicroactionConnectionInput!) {
    trainConnectionMicroaction(input: $input) {
      TicketNumber
      EmailPending
      BlockedMicroactions
      StartDate
      PendingInvoiceItems {
        ID
        Title
        Description
        Price {
          Amount
        }
      }
      OppositeDirectionTicket {
        TicketNumber
        EmailPending
        BlockedMicroactions
        StartDate
        PendingInvoiceItems {
          ID
          Title
          Description
          Price {
            Amount
          }
        }
      }
    }
  }
`)

export const readMessageRecipientAmountQuery = graphql(`
  query ReadMessageRecipientAmount($input: MessageRecipientAmountInput!) {
    readMessageRecipientAmount(input: $input)
  }
`)

export const sendMessageMutation = graphql(`
  mutation SendMessage($input: SendMessageInput!) {
    sendMessage(input: $input) {
      ID
      EmailMessages {
        ID
        Created
        Type
        EmailAmount
        EmailSentAmount
        Summary
        ContentDE
        ContentEN
      }
    }
  }
`)

export const lostLoginPasswordMutation = graphql(`
  mutation LostLoginPassword($input: LostLoginPasswordInput!) {
    lostLoginPassword(input: $input)
  }
`)

export const resetLoginPasswordMutation = graphql(`
  mutation ResetLoginPassword($input: ResetLoginPasswordInput!) {
    resetLoginPassword(input: $input)
  }
`)

export const readSwitchableBookingsQuery = graphql(`
  query ReadSwitchableBookings($TicketNumber: String!, $CollectionID: String!, $SameEntity: Boolean!) {
    readSwitchableBookings(TicketNumber: $TicketNumber, CollectionID: $CollectionID, SameEntity: $SameEntity) {
      ID
      TicketNumber
      WagonNumber
      CabinNumber
      EntityType
      Entities
    }
  }
`)

export const addPaybackValueMutation = graphql(`
  mutation AddPaybackValue($TicketNumber: String!, $Type: String!, $MembershipNumber: String!) {
    addPaybackValue(TicketNumber: $TicketNumber, Type: $Type, MembershipNumber: $MembershipNumber) {
      TicketNumber
      Customer {
          TcsMemberNumber
      }
      Memberships {
          ID
          Title
          Payload
      }
      PaybackValues {
        ID
        Created
        Amount
        RemoteAmount
        TCSCashback
        Definite
        Type
        MembershipNumber
        ErrorMessage
      }
    }
  }
`)

export const updatePaybackValueMutation = graphql(`
  mutation UpdatePaybackValue($PaybackID: ID!, $MembershipNumber: String) {
    updatePaybackValue(PaybackID: $PaybackID, MembershipNumber: $MembershipNumber) {
      TicketNumber
      Customer {
        TcsMemberNumber
      }
      Memberships {
        ID
        Title
        Payload
      }
      PaybackValues {
        ID
        Created
        Amount
        RemoteAmount
        TCSCashback
        Definite
        Type
        MembershipNumber
        ErrorMessage
      }
    }
  }
`)

export const deletePaybackValueMutation = graphql(`
  mutation DeletePaybackValue($PaybackID: ID!) {
    deletePaybackValue(PaybackID: $PaybackID) {
      TicketNumber
      Customer {
          TcsMemberNumber
      }
      Memberships {
          ID
          Title
          Payload
      }
      PaybackValues {
        ID
        Created
        Amount
        RemoteAmount
        TCSCashback
        Definite
        Type
        MembershipNumber
        ErrorMessage
      }
    }
  }
`)

export const validatePaybackMembershipQuery = graphql(`
    query ValidatePaybackMembership($Type: String!, $MembershipNumber: String!) {
      validatePaybackMembership(Type: $Type, MembershipNumber: $MembershipNumber)
    }
`)

export const readWagonTypesQuery = graphql(`
    query ReadWagonTypes {
      readWagonTypes {
        ID
        Code
      }
    }
`)
